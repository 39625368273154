import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import LatestInsights from "src/components/insights/Latest"
import Content from "src/components/Content"

import "./teamTemplate.scss"

const TeamTemplate = ({data: {page: {frontmatter: {
  meta, team
}}}}) => {

  const [showDetail, setShowDetail] = useState(null)

  const openDetail = e => {
    const member = e.target.closest(".member")
    const memberId = member.dataset.id
    setShowDetail(memberId)

    document.getElementsByTagName("body")[0].classList.add("no-scroll")
    document.addEventListener("mousedown", handleClickOutside)
  }

  const handleClickOutside = event => {
    if (!event.target.closest(".member-detail")) {
      closeDetail()
    }
  }

  const closeDetail = () => {
    setShowDetail(null)
    document.getElementsByTagName("body")[0].classList.remove("no-scroll")
  }
  
  return (
    <Layout meta={meta}>
      <div className="team-template">
        <Header>
          <h1>Our Team</h1>
        </Header>
        <div className="team-description">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <Content html={team.description} />
              </div>
            </div>
          </div>
        </div>

        <div className="team-about" id="team">
          <div className="container leadership">
            <div className="row">
              {team.members.map(member => (
                <div
                  key={member.name}
                  className="col-md member"
                  data-id={member.id}
                  onClick={e => openDetail(e)}
                > 
                  <div className="member-image">
                    <GatsbyImage image={member.image.childImageSharp.gatsbyImageData} alt={member.name} />
                  </div>
                  <div className="member-info">
                    <h3 className="member-name">{member.name}</h3>
                    <h5 className="member-title">{member.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {team.members.map(member => {

          const name = member.name.split(" ")
          const firstName = name[0]
          const lastName = name[1]
        
          return (
            <div 
              key={member.name}
              className={`member-detail ${showDetail === member.id ? "open" : ""}`} 
              id={member.id}
            >
              <div className="member-detail-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 offset-md-1">
                      <div className="row member-top">
                        <div className="col-md">
                          <GatsbyImage image={member.image.childImageSharp.gatsbyImageData} alt={member.name} />
                        </div>
                        <div className="col-md">
                          <div className="member-right">
                            <div>
                              <h1>{firstName} <br />{lastName}</h1>
                              <h5 className="member-title">{member.title}</h5>
                            </div>
                            <span className="member-links">
                              {member.links && Object.keys(member.links).map(link => {
                                if(!member.links[link]) return ""
                                return (
                                  <a 
                                    key={link}
                                    href={member.links[link]} 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={`/img/team-${link}.svg`} alt={link} />
                                  </a>
                                )
                              })}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="member-bio">{member.bio}</p>
                    </div>
                  </div>
                </div>
                <button 
                  className="close-detail"
                  onClick={closeDetail}
                >
                  Close
                </button>
              </div>
            </div>
          )
        })}

      </div>
    </Layout>
  )
}

export default TeamTemplate

export const pageQuery = graphql`query ($path: String!, $quote: String!) {
  page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
    html
    ...MetaFragment
    ...HeroFragment
    frontmatter {
      team {
        description
        members {
          name
          id
          image {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          title
          bio
          links {
            twitter
            github
            dribble
            linkedin
          }
        }
      }
    }
  }
  ...quoteQuery
}
`
